import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const NfseService = {
  postStatusUpdate (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/nfse/status/update`, data)
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  refreshStatus (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/bill/${idBill}/refresh`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/report/alert/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  historicStatus (idInvoice) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse/${idInvoice}/status/historic`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/nfse-international/tools
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  toolsNfseInternacional (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse-international/tools?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/nfse-international/tools/csv
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  toolsNfseInternacionalCsv (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse-international/tools/csv?${queryString}`, {}).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/nfse-international/tools/csv
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  toolsNfseInternacionalForceSync (filter) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/nfse-international/tools/sync`, filter).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * /api/v2/nfse-international/tools/csv
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  toolsNfseInternacionalForceSyncBulk (filter) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/nfse-international/tools/sync/bulk`, filter).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default NfseService
