<template>
  <div class="col-sm-4 form-group mb-4" :class="rootClassName">
    <label v-if="label"
      :class="[{ 'royalc-error-input': error, 'small grey-label': greyField }]" >
      {{ this.$i18n.t(label) }}
    </label>
    <v-select name="nome"
              label="nome"
              :disabled="disabled"
              :placeholder="$t('filter.enter_text')"
              :clearable="false"
              :searchable="true"
              :class="[{ 'grey-field': greyField }]"
              v-model="selected"
              @search="fetchOptions"
              :options="optionList"
              @input="onSelect" :filter-by="filter" >
      <template slot="no-options" slot-scope="{searching, search }">
        <em v-if="!searching" style="opacity: 0.5;">
          Digite para buscar uma pessoa
        </em>
        <em v-else style="opacity: 0.5;">
          Nenhuma opção encontrada para {{ search }}
        </em>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.nome }} {{ option.cpfCnpj ? ('(' + option.cpfCnpj + ')') : '' }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.nome }} {{ option.cpfCnpj ? ('(' + option.cpfCnpj + ')') : '' }}
        </div>
      </template>
    </v-select>
    <i v-if="showInfo && selected" v-on:click="showPersonData()" class="pull-right fa fa-info-circle"></i>
    <transition name="slide" mode="">
      <small v-show="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PersonService from '@/services/PersonService'
import PersonInfoView from '../../views/Invoice/Invoice/PersonInfoView'

export default {
  inheritAttrs: false,
  name: 'PessoaField',
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'pessoa_' + _this._uid
  },
  data () {
    return {
      optionList: [],
      id: null,
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    showInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    greyField: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: 'form.proposal.customer'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    }
  },
  methods: {
    showPersonData () {
      let _this = this
      _this.isLoading = true

      PersonService.getPerson(_this.selected.id).then(res => {
        _this.$modal.show(
          PersonInfoView,
          { isSuperAdmin: _this.isSuperAdmin,
            person: res.data.data },
          { draggable: false, width: '78%', height: 'auto', adaptive: true },
          { 'before-close': event => {} }
        )
        _this.isLoading = false
      })
    },
    /**
     * Triggered when search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    fetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          loading(true)
          let filters = {
            'searchTerm': search
          }

          PersonService.getPersonsActive(filters).then(res => {
            _this.optionList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onSelect (val) {
      this.$emit('input', val)
    },
    filter (option, label, search) {
      let temp = search.toLowerCase().trim()

      return (
        option.nome.toLowerCase().indexOf(temp) > -1 ||
        ((option.cpfCnpj) && option.cpfCnpj.toLowerCase().indexOf(temp) > -1)
      )
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    }
  }
}
</script>

<style>
.grey-label {
  color: gray;
  margin-bottom: 0;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}
</style>
